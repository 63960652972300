<template>
    <BannerTemplate :bannerSource="bannerSource" :title="`Mijn Roadpursuit`" :intro="''">
        <LoadingIcon :isLoading="isLoading" />
        <v-container>
            <v-row>
                <v-col cols="12" class="py-0">
                    <v-form ref="form" class="ma-2" :disabled="isLoading"
                            v-model="valid"
                            lazy-validation>
                        <v-text-field v-model="email"
                            :rules="[rules.email]"
                            label="E-mailadres"
                            required></v-text-field>

                        <v-text-field
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required]"
                            color="primary"
                            :type="showPassword ? 'text' : 'password'"
                            label="Wachtwoord"
                            @keydown.native.enter="validate"
                            v-model="password"
                            @click:append="showPassword = !showPassword">
                        </v-text-field>

                        <v-btn color="primary" block @click.stop="validate" class="mt-5 mb-5" :disabled="isLoading">
                            Login
                        </v-btn>
                    </v-form>
                </v-col>
            </v-row>
            <v-row class="justify-center text-center" no-gutters>
                <v-card-actions>
                    <v-container >
                        <v-row dense>
                            <v-col cols="12">
                                Wachtwoord vergeten?
                                <span class="link" @click="$router.push({ path: `/mijn-roadpursuit/wachtwoord-vergeten` })"> 
                                    Vraag nieuwe aan.
                                </span>
                            </v-col>
                            <v-col cols="12">
                                Geen account?
                                <span class="link" @click="$router.push({ path: `/mijn-roadpursuit/registratie` })">Registreer u hier.</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-row>
        </v-container>
    </BannerTemplate>
</template>

<script>
import BannerTemplate from '@/components/common/BannerTemplate'
import { mapState, mapActions } from 'vuex'
import DataMixin from "@/mixins/data.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";
import LoadingIcon from '@/components/common/LoadingIcon';

export default {
    name: 'Login',
    components: 
    {
        LoadingIcon,
        BannerTemplate
    },
    computed: {
        ...mapState('account', ['status']),
        isLoading() {
            return this.status.loggingIn;
        }
    },
    mixins: [DataMixin, MetaTagsMixin],
    data: () => ({
        bannerSource: require('@/assets/album/Login/login-01.jpg'),
        email: '',
        password: '',
        submitted: false,
        showPassword: false,
        isVerifying: false,
        valid: true,
        select: null,
        items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
        ],
        checkbox: false,
        redirectPath: '/',
    }),
    mounted()
    {
        // force refresh
        this.$forceUpdate();
        this.redirectPath = this.$route.query.redirect;
        this.$store.commit('setFooterVisibility', true);
    },
    metaInfo() {
        return {
        title: '| Login',
        meta: [
            this.getKeywords(`Login, Mijn Roadpursuit`),
            this.getMetaDescription(`Roadpursuit login`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Roadpursuit login`, `Roadpursuit login`, `${window.location.origin}${this.bannerSource}`, 'Login'),
        ]
        };
    },
    methods: 
    {
        ...mapActions('account', ['login']),
        validate()
        {
            const valid = this.$refs.form.validate()
            if (valid)
            {
                let { email, password, redirectPath } = this;
                this.login({  email, password, redirectPath})
            }
        },
        reset()
        {
            this.$refs.form.reset()
        },
    }
}
</script>